import Vue from 'vue';
import Router from 'vue-router';
import Layout from './layout/Layout.vue';
import Index from './pages/Index.vue';
import RedPillGo from './pages/RedPillGo.vue';
import RedPillStudio from './pages/RedPillStudio.vue';
import VoiceEngine from './pages/RedPillVoiceEngine.vue';
import About from './pages/About.vue';
import Pricing from './pages/Pricing.vue';
import Downloads from './pages/Downloads.vue';
import Maintenance from './pages/Maintenance.vue';
import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import i18n from './i18n'
Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: to => { 
        return `/${i18n.locale}`;
      }
    },
    {
      path: '/:lang',
      name: "lang",
      //components: {render (c) { return c('router-view') }},
      components: { default: Layout, header: MainNavbar, footer: MainFooter },
      props: {
          header: { colorOnScroll: 400 },
          footer: { backgroundColor: 'black' }
        },
      children: [
        // {
        //   path: '/',
        //   name: 'maintenance',
        //   component: Maintenance,
        // },
        {
          path: '/',
          name: 'index',
          component: Index,
        },
        {
          path: 'redpillgo',
          name: 'redpillgo',
          components: { default: RedPillGo },
         
        },
        {
          path: 'redpillstudio',
          name: 'redpillstudio',
          components: { default: RedPillStudio },
         
        },
        {
          path: 'voiceengine',
          name: 'voiceengine',
          components: { default: VoiceEngine },
         
        },
        {
          path: 'about',
          name: 'about',
          component: About,
        },
        {
          path: 'pricing',
          name: 'pricing',
          component: Pricing,
        },
        {
          path: 'download',
          name: 'download',
          component: Downloads,
        },
        {
          path: 'login',
          name: 'login',
          components: { default: Login, header: MainNavbar },
          props: {
            header: { colorOnScroll: 400 }
          }
        },
        {
          path: 'profile',
          name: 'profile',
          components: { default: Profile, header: MainNavbar },
          props: {
            header: { colorOnScroll: 400 },
            footer: { backgroundColor: 'black' }
          }
        }
      ]
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
