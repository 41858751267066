<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png'); z-index: 0 !important;"
      >
      <video ref="videoRef"  loop="loop" muted="muted" class="video" ></video>
      </parallax>
    </div>
    <n-button type="button" class="btn btn-icon btn-round btn-primary" @click="OnMute" style="z-index: 10;position: relative; background-color: transparent; top: -50px; left: 0%;">
      <i v-if="muted" class="now-ui-icons" ><b-icon icon="volume-mute"></b-icon></i>
      <i v-else class="now-ui-icons" ><b-icon icon="volume-down"></b-icon></i>
    </n-button>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <div class="disclaimer font-weight-semi" style="color: white;">
                <vue-typed-js ref="disclaimer" class="d-block" :loop="true" :strings=strings>
                  <span class="typing"></span>
                </vue-typed-js>
            </div>
            <h2 class="title" style="color: white;">{{$t('about.title')}}</h2>
            <h5 class="description" style="color: white;">{{$t('about.description')}}</h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
      </div>
    </div>
   
 

      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('about.contact')}}</h2>
            <h5 class="description" style="color: white;">
              <p>4F, Ruiguang Rd., Neihu Dist., Taipei City 114 成境科技有限公司</p>
              <p>泛太丝亚企业管理顾问（上海）有限公司</p>
              <p>Red Pill Lab Limited, Central, Hong Kong</p>
              <p>Email: contact@rplab.ai / rhs@rplab.ai</p>
            </h5>
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'maintenance',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  watch: 
  {
    '$i18n.locale': function(newVal, oldVal)
    {


        this.$refs.disclaimer.typedObj.strings = this.$t('mnt.disclaimer');
        this.$refs.disclaimer.typedObj.reset();
    }
  },
  mounted()
  {
    
    this.$refs.videoRef.src = "/img/RPGPromo.mp4";
    this.$refs.videoRef.play();
    this.$root.$emit('hide_routes')

  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    }

  },
  data() {
    return {
      strings: this.$t('mnt.disclaimer'),
      muted: true
    };
  }
};
</script>
<style>
.disclaimer {
    font-size: 1.5rem;
  }
video {
  position: absolute;
  height: 100%;
  top: 0%;
  left: 50%;

  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 1;

} 

</style>
