<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png')"
      >
       <video ref="videoRef" loop="loop" muted="muted" class="video" ></video>
      </parallax>
      </div>
      <n-button type="button" class="btn btn-icon btn-round btn-primary" @click="OnMute" style="z-index: 10;position: relative; background-color: transparent; top: -50px; left: 0%;">
      <i v-if="muted" class="now-ui-icons" ><b-icon icon="volume-mute"></b-icon></i>
      <i v-else class="now-ui-icons" ><b-icon icon="volume-down"></b-icon></i>
    </n-button>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('voiceengine.title')}}</h2>
            <h5 class="description" style="color: white;">
             {{$t('voiceengine.description')}}
            </h5>
          </div>
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/VoiceEngine.png">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;"> {{$t('voiceengine.features[0].title')}}</h4>
                <p>{{$t('voiceengine.features[0].text')}}</p>
            </div>
        </div>
        <div id="feature">
            <div class='column' id="side2">
               <h4 class="title" style="color: white;">{{$t('voiceengine.features[1].title')}}</h4>
                <p>{{$t('voiceengine.features[1].text')}}</p>
            </div>
            <div class='column' id="main2">
              <img src="/img/voice-engine-emotions.jpg">
            </div>
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/voice-engine-gaming.jpg">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;">{{$t('voiceengine.features[2].title')}}</h4>
                <p>{{$t('voiceengine.features[2].text')}}</p>
            </div>
        </div>
      </div>
     
    </div>
    
  <br><br>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'voice-engine',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  mounted() {
      this.$refs.videoRef.src = "/img/RPVEPromo.mp4";
      this.$refs.videoRef.play();
      
      
  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    }

  },
  data() {
    return {
      muted: true
    };
  }
};
</script>
<style>
 @import '../assets/scss/redpill.scss';
</style>
