<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png')"
      >
       <video ref="videoRef"  loop="loop" muted="muted" class="video" ></video>
      </parallax>
      
    </div>
    <n-button type="button" class="btn btn-icon btn-round btn-primary" @click="OnMute" style="z-index: 10;position: relative; background-color: transparent; top: -50px; left: 0%;">
      <i v-if="muted" class="now-ui-icons" ><b-icon icon="volume-mute"></b-icon></i>
      <i v-else class="now-ui-icons" ><b-icon icon="volume-down"></b-icon></i>
    </n-button>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('redpillgo.title')}}</h2>
            <h5 class="description" style="color: white; font-size: large;">
              {{$t('redpillgo.description')}}
            </h5>
          </div>
        </div>
        <br><br>
        <div v-if="showSurvey" class="container" style="text-align:center">
        <!-- <n-button v-if="!expandSurvey" type="primary" round size="lg" @click="expandSurvey=true;">{{$t(`index.survey_button`)}}</n-button>
        <RPGSurvey v-if="expandSurvey" style="background-color:rgba(255, 255, 255, 0.1); color: white; text-align:left"></RPGSurvey > -->
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/hub.png">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;"> {{$t('redpillgo.features[0].title')}}</h4>
                <p>{{$t('redpillgo.features[0].text')}}</p>
                
            </div>
        </div>
        <div id="feature">
            <div class='column' id="side2">
               <h4 class="title" style="color: white;">{{$t('redpillgo.features[1].title')}}</h4>
                <p>{{$t('redpillgo.features[1].text')}}<a href="https://go.rplab.online/" target="_blank">https://go.rplab.online/</a>.</p>
                
            </div>
            <div class='column' id="main2">
              <img src="/img/rpg_web.jpg">
            </div>
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/retargeting.png">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;">{{$t('redpillgo.features[2].title')}}</h4>
                <p>{{$t('redpillgo.features[2].text')}}</p>
            </div>
        </div>
      </div>
    </div>

  <br><br>
   
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
// import RPGSurvey from '@/components/RPGSurvey.vue'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    // RPGSurvey
  },
  mounted() {
      this.$refs.videoRef.src = "/img/RPGPromo.mp4";
      this.$refs.videoRef.play();
      if(localStorage.getItem('surveyCompleted')==='true')
        this.showSurvey = false;
     
  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    }

  },
  data() {
    return {
      showSurvey: true,
      expandSurvey: false,
      muted: true,
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style>
 @import '../assets/scss/redpill.scss';
</style>
