/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import VideoParallax from 'vuetify-video-parallax';
import i18n from './i18n'
import VueTypedJs from "vue-typed-js";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(VueTypedJs);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false;

Vue.use(NowUiKit);
Vue.use(VideoParallax);
Vue.use(i18n);

router.beforeEach((to,from,next) =>{
    let language = to.params.lang;
    if(!language){
        language = 'en'
    }
    i18n.locale = language;
    next();
})

new Vue({
 router,
 i18n,
 render: h => h(App)
}).$mount('#app');
