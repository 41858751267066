
<template>
	<div class="layout" >
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: "layout",
  props: {
    header: {
      required: false,
      default: null
    },
    footer: {
      required: false,
      default: null
    }
  },
  // render() {
  //   return 'hello world!'
  // }
};
</script>