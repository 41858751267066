<template>
  <footer v-if="hide_all==false"
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <a :href="$t('legal.terms.url')" target="_blank" rel="noopener" style="margin-left:30px;">{{$t('legal.terms.title')}}</a>
      <a :href="$t('legal.privicy.url')" target="_blank" rel="noopener" style="margin-left:30px;">{{$t('legal.privicy.title')}}</a>
      <a :href="$t('legal.return.url')" target="_blank" rel="noopener" style="margin-left:30px;">{{$t('legal.return.title')}}</a>
      <nav>
        <ul>
          <li>
            
            <!-- <router-link :to="`/${$i18n.locale}/about`">
               About Us
            </router-link> -->
          </li>
          <!-- <li>
            <a href="https://www.rplab.ai/">
              Blog
            </a>
          </li> -->
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Red Pill Lab Ltd.
         
        <!-- <a href="/" target="_blank" rel="noopener" style="margin-left:30px;">Terms of Service</a>
        <a href="/" target="_blank" rel="noopener" style="margin-left:30px;">Privacy Policy</a> -->
        
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'footer',
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear(),
      hide_all: false,
    };
  },
  mounted()
  {
    this.$root.$on('hide_all', () =>{
      //console.log("HIDE");
      this.hide_all = true;
    });
  }
};
</script>
<style></style>
