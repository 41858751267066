<template>
  <div id="app" style="background-color:rgb(19, 19, 23)">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {};
</script>
