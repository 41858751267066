<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png')"
      >
      </parallax>
     
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('about.title')}}</h2>
            <h5 class="description" style="color: white;">
              {{$t('about.description')}}
            </h5>
          </div>
        </div>
        <div id="feature">
          <div class='column' id="main">
              <img src="/img/about_history.png">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;"> {{$t('about.features[0].title')}}</h4>
                <p>{{$t('about.features[0].text')}}</p>
                
            </div>
        </div>
        <div id="feature">
            <div class='column' id="side2">
               <h4 class="title" style="color: white;">{{$t('about.features[1].title')}}</h4>
                <p>{{$t('about.features[1].text')}}</p>
                
            </div>
            <div class='column' id="main2">
              <img src="/img/rpg.png">
            </div>
        </div>
        <!-- <div id="feature">
          <div class='column' id="main">
              <img src="/img/bg11.jpg">
            </div>
            <div class='column' id="side">
               <h4 class="title" style="color: white;">{{$t('about.features[2].title')}}</h4>
                <p>{{$t('about.features[2].text')}}</p>
            </div>
        </div> -->
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title" style="color: white;">Leadership</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="/img/RH.webp"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title" style="color: white;">{{$t('about.team[0].name')}}</h4>
                <p class="category text-primary">{{$t('about.team[0].position')}}</p>
                <p class="description" style="color: white;">
                  {{$t('about.team[0].text')}}
                </p>
                <a href="https://www.linkedin.com/in/rh-shih-3b048b8a/" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="/img/dobry.webp"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title" style="color: white;">{{$t('about.team[1].name')}}</h4>
                <p class="category text-primary">{{$t('about.team[1].position')}}</p>
                <p class="description" style="color: white;">
                   {{$t('about.team[1].text')}}
                </p>
                <!-- <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a> -->
                <a href="https://www.linkedin.com/in/dobromir-todorov-27819873/" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="/img/Brian.png"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title" style="color: white;">{{$t('about.team[2].name')}}</h4>
                <p class="category text-primary">{{$t('about.team[2].position')}}</p>
                <p class="description" style="color: white;">
                  {{$t('about.team[2].text')}}
                </p>
                
                <a href="https://www.linkedin.com/in/bbdragon/" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">

      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">{{$t('about.contact')}}</h2>
            <h5 class="description" style="color: white;">
              <p>{{$t('about.address')}}</p>
              <p>{{$t('about.address_2')}}</p>
              <!-- "泛太丝亚企业管理顾问（上海）有限公司 -->
              <p>Red Pill Lab Limited, Central, Hong Kong</p>
              <p>Email: contact@rplab.ai / support@rplab.ai / sales@rplab.ai</p>
              <!-- <p>{{$t('about.phone')}}</p> -->
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
