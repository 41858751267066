<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template v-if="hide_all==false">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img class="n-logo" style="max-width: 20%;" src="/img/redpill.png" alt="" />
        Red Pill Lab
      </router-link>
      
    </template>
    <template slot="navbar-menu" v-if="hide_all==false">
      
        
        <router-link class="nav-link" style="color:white;" :to="`/${$i18n.locale}/download`" v-if="hidden==false"> 
          <i class="now-ui-icons"><b-icon icon="cloud-download" aria-hidden="true"></b-icon></i>
          
            
          <p>{{$t('navbar.downlaod')}}</p>
        </router-link>
     
        <!-- <i class="now-ui-icons"><b-icon icon="box-seam" aria-hidden="true"></b-icon></i> -->
        
        <drop-down
          tag="li"
          :title= "product_text"
          icon="box-seam"
          class="nav-item"
          v-if="hidden==false"
        >
         
          <nav-link :to="`/${$i18n.locale}/redpillgo`">
            <i class="now-ui-icons "><b-icon icon="file-person" ></b-icon></i> Red Pill Go
          </nav-link>
          <nav-link :to="`/${$i18n.locale}/redpillstudio`">
            <i class="now-ui-icons"><b-icon icon="columns" ></b-icon></i> Red Pill Studio
          </nav-link>
          <nav-link :to="`/${$i18n.locale}/voiceengine`">
            <i class="now-ui-icons"><b-icon icon="mic" ></b-icon></i> Red Pill Voice Engine
          </nav-link>
        </drop-down>
        <router-link class="nav-link" style="color:white;" :to="`/${$i18n.locale}/pricing`" v-if="hidden==false"> 
          <i class="now-ui-icons"><b-icon icon="currency-dollar"></b-icon></i>
          <p>{{$t('navbar.pricing')}}</p>
        </router-link>
        <li class="nav-item">
          
        <router-link class="nav-link" style="color:white;" :to="`/${$i18n.locale}/about`" v-if="hidden==false"> 
          <i class="now-ui-icons"><b-icon icon="info-circle"></b-icon></i>
           
          <p>{{$t('navbar.about')}}</p>
        </router-link>

        

      </li>
      
      

      <div class="nav-link">
      <n-switch
            v-model="switches.defaultOn"
            on-text="EN"
            off-text="中"
            @input="onLocale"
          ></n-switch></div>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/RedPillLab"
          target="_blank"
        >
        
          <!-- <i class="fab fa-facebook-square"></i> -->
          <i class="now-ui-icons"><b-icon icon="facebook" aria-hidden="true"></b-icon></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on YouTube"
          data-placement="bottom"
          href="https://www.youtube.com/channel/UC-1xCA0871zvSz96zPwP5cQ"
          target="_blank"
        >
          <!-- <i class="fab fa-youtube"></i> -->
          <i class="now-ui-icons"><b-icon icon="youtube" aria-hidden="true"></b-icon></i>
          <p class="d-lg-none d-xl-none">Youtube</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink, Switch } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Switch.name]: Switch,
    [Popover.name]: Popover
  },
  mounted()
  {
    this.$root.$on('hide_routes', () =>{
      //console.log("HIDE");
      this.hide_routes();
    });
    this.$root.$on('hide_all', () =>{
      //console.log("HIDE");
      this.hide_all = true;
    });
  },
  methods: 
  {
    hide_routes()
    {
      //console.log("HIDE");
      this.hidden = true;
    },
    onLocale(locale)
    {
      if(locale)
        locale ='en'
      else
        locale = 'zh'

      //this.$i18n.locale = locale;
      this.$root.$i18n.locale = locale;
      this.$router.push(
        {
          params:{lang: locale}
        }
      )
      this.product_text = this.$t('navbar.products')

    }
  },
  data() {
    return {
      product_text: this.$t('navbar.products'),
      hidden: false,
      hide_all:false,
      switches: {
        defaultOn: this.$i18n.locale=='en',
        defaultOff: this.$i18n.locale!='en'
      }
    };
  }
};
</script>

<style scoped>
li.disabled {
  pointer-events: none;
  cursor: default;
}
</style>
