<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png') z-index: 0 !important;"
      >
      <video ref="videoRef"  loop="loop" muted="muted" class="video" ></video>
      </parallax>
    </div>
    <n-button type="button" class="btn btn-icon btn-round btn-primary" @click="OnMute" style="z-index: 10;position: relative; background-color: transparent; top: -50px; left: 0%;">
      <i v-if="muted" class="now-ui-icons" ><b-icon icon="volume-mute"></b-icon></i>
      <i v-else class="now-ui-icons" ><b-icon icon="volume-down"></b-icon></i>
    </n-button>
      
    <div class="content-center brand" style="text-align:center">
      <img class="n-logo" src="/img/redpill.png" alt="" />
      <h1 class="h1-seo" style="font-weight: bold; margin-top: -20px; color: white;">Red Pill Lab</h1>
      <h3 style=" margin-top: -20px; color: white;">{{$t('index.slogan')}}</h3>
    </div>
    <div class="content-center brand" style="text-align:center">
      <br><br>
      <h3 style=" margin-top: -20px; color: white;">{{$t('index.tagline')}}</h3>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Go</h2>
            <img src="/img/RPG_1080p.jpg">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillgo')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/redpillgo`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Studio</h2>
            <img src="/img/rps_ui.png">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillstudio')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/redpillstudio`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>

    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title" style="color: white;">Red Pill Voice Engine</h2>
            <img src="/img/VoiceEngineBB.png">
            <br><br>
            <h4 class="description" style="color: white;">{{$t('index.redpillvoiceengine')}}</h4>
          </div>
        </div>
      </div>
      <div class="container" style="text-align:center">
        <n-button type="primary" round size="lg" @click="$router.push(`/${$i18n.locale}/voiceengine`)">{{$t('index.learn')}}</n-button>
      </div>
    </div>
  
    <br><br>

    <div class="section section-about-us" >
      <div class="container" >
        <div class="row">
            <div class="col-md-12 ml-auto mr-auto text-center" >
                <h2 class="title" style="color: white;">{{$t('index.news_label')}}</h2>
                <div v-if="filteredNews.length==0" id="skeletonLoader">
                  <div class="skeleton-article">
                    <div class="skeleton-image"></div>
                    <div class="skeleton-text-group">
                      <div class="skeleton-title"></div>
                      <div class="skeleton-text"></div>
                      <div class="skeleton-text"></div>
                    </div>
                  </div>

                  <div class="skeleton-article">
                    <div class="skeleton-image"></div>
                    <div class="skeleton-text-group">
                      <div class="skeleton-title"></div>
                      <div class="skeleton-text"></div>
                      <div class="skeleton-text"></div>
                    </div>
                  </div>

                  <div class="skeleton-article">
                    <div class="skeleton-image"></div>
                    <div class="skeleton-text-group">
                      <div class="skeleton-title"></div>
                      <div class="skeleton-text"></div>
                      <div class="skeleton-text"></div>
                    </div>
                  </div>
                </div>
                <div v-if="filteredNews.length>0" class="news-container">
                    <div v-for="(news, index) in filteredNews" :key="index" class="news-article clearfix">
                        <h3>{{ news.Title }}</h3>
                        <img class="news-image" :src="news.Image" :alt="news.Title" />
                        <div class="news-text-group">
                            <div class="published-date" style="text-align: right;">{{ formattedDate(news.PostTime) }}</div>
                            <p>{{ news.Content }}</p>
                            <a v-if="news.Origin" :href="news.Origin" target="_blank">{{$t('index.learn')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>

    <div class="col-md-8 ml-auto mr-auto text-center">
      <h2 class="title" style="color: white;">{{$t('index.partners')}}</h2>
    </div>
    <carousel-section></carousel-section>

  
  </div>
</template>
<script>

import { Parallax, Button } from '@/components';
// import RPGSurvey from '@/components/RPGSurvey.vue'
//import ProgressPagination from './components/ProgressPagination';
import CarouselSection from './components/CarouselSection'
export default {
  name: 'index',
  bodyClass: 'landing-page',
  watch: 
  {
    '$i18n.locale': function(newVal, oldVal)
    {

      if(newVal=="en")
        this.local = "En"
      else if(newVal=="zh")
        this.local = "Zh"
    }
  },
  mounted() {
      this.$refs.videoRef.src = "/img/RPGPromo.mp4";
      this.$refs.videoRef.play();
      if(localStorage.getItem('surveyCompleted')==='true')
        this.showSurvey = false;
      this.GetNews();

      console.log("Local "+this.$i18n.locale);
  },
  methods: 
  {

    OnMute()
    {
      this.$refs.videoRef.muted = !this.$refs.videoRef.muted;
      this.$refs.videoRef.play();
      this.muted = this.$refs.videoRef.muted;
    },
    async GetNews()
    {
      const url = 'https://pb5r3fim2c.execute-api.ap-northeast-2.amazonaws.com/dev/items';

      try {
          const response = await fetch(url);
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.news_provider = data.news;
          //console.log(data.news);  // Handle the response data here
          //console.log("Local ");
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(this.$i18n.locale, options);
    }

  },
  data() {
    return {
      // showSurvey: true,
      expandSurvey: false,
      muted: true,
      news_provider:[],
      local: "En"
    };
  },
  computed: {
    
    filteredNews() {
      console.log("Local "+this.$i18n.locale);
      return this.news_provider.filter(news => news.Lang.toLowerCase() == this.$i18n.locale && news.Visible)
      .sort((a, b) => new Date(b.PostTime) - new Date(a.PostTime));
    }
  },
  components: {
    Parallax,
    // RPGSurvey,
    [Button.name]: Button,
    CarouselSection,
    //ProgressPagination,
  }
};
</script>
<style>
video {
  position: absolute;
  height: 100%;
  top: 0%;
  left: 50%;

  -ms-transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  text-align: center;
  z-index: 1;

}
.news-container {
    max-height: 540px; /* Maximum height to show 3 articles, each 180px high */
    overflow-y: auto;
    padding-right: 10px; /* Add padding to avoid overlapping */
    border-radius: 8px; /* Rounded corners */
}


.news-article {
    padding: 10px;
    display: block;
    margin-bottom: 20px;
    color: #e2e2e2;
    border-radius: 8px; /* Rounded corners */
    background-color: #3333334f;  /* Background color to distinguish */
    box-shadow: 4px 4px 4px 4px rgba(0,0,0,0.2); /* Subtle shadow for depth */
}

.news-image {
    width: 320px;
    height: 180px;
    border-radius: 8px;
    object-fit: cover;
    float: left;
    margin-right: 16px;
}
@media (max-width: 600px) {
    .news-image {
        float: none;             /* Remove float on mobile */
        display: block;          /* Make it a block-level element */
        margin: 0 auto 16px auto; /* Center the image and add margin below */
    }
}

.news-text-group h3,
.news-text-group p,
.news-text-group .published-date {
    margin: 0;
    color: #e2e2e2;
}

.news-text-group h3 {
    margin-bottom: 8px;
}

.news-text-group p:first-of-type {
    text-indent: 2em;  /* Adjust the value to increase or decrease the indentation */
    text-align: justify;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
/* Custom scrollbar styles */
.news-container::-webkit-scrollbar {
    width: 8px;
   
}

.news-container::-webkit-scrollbar-thumb {
    background-color: #555; /* Darker color for thumb */
    border-radius: 4px;
}

.news-container::-webkit-scrollbar-track {
    background-color: #333; /* Darker background for track */
    border-radius: 8px; /* Rounded corners */
}

@media (max-width: 768px) {
    .news-article {
        flex-direction: column;
    }
}
.skeleton-article {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
  }
.skeleton-image {
    width: 120px;
    height: 80px;
    border-radius: 8px;
    background-color: #e0e0e0;
    animation: shimmer 1.5s infinite linear alternate;
    
    background-size: 1000px 100%;
  }

  .skeleton-text-group {
    flex-grow: 1;
  }

  .skeleton-title {
    width: 60%;
    height: 16px;
    margin-bottom: 8px;
    background-color: #555;
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear alternate;
  }

  .skeleton-text {
    height: 12px;
    margin-bottom: 6px;
    background-color: #555;
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear alternate;
  }

  .skeleton-text:last-child {
    width: 80%;
  }

  /* Shimmer animation */
  @keyframes shimmer {
    0% {
      background-color:  #999;
    }
    100% {
      background-color: #555;
    }
  }

</style>
