<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('/img/MegaGrantsPoster.png')"
      >
      </parallax>
     
    </div>

    <div class="section section-team text-center">
      <h2 class="title" style="color: white;">{{$t('pricing.title')}}</h2>
      <div class="container">
        <!-- <h2 class="title" style="color: white;">Red Pill Go</h2> -->
        <div class="team">
          <div class="row">
            <div class="col-md-2">
              <div class="team-player">
                <!-- <img
                  src="/img/RH.webp"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                /> -->
                <!-- <i class="now-ui-icons"><b-icon icon="calendar3" class="rounded-circle p-1" font-scale="7" variant="white"></b-icon></i> -->
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="calendar-week" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[0].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[0].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[0].description')}}
                </p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="team-player">
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="calendar-week" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[1].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[1].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[1].description')}}
                </p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="team-player">
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="calendar-week" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[2].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[2].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[2].description')}}
                </p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="team-player">
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="coin" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[3].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[3].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[3].description')}}
                </p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="team-player">
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="coin" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[4].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[4].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[4].description')}}
                </p>
              </div>
            </div>
            <div class="col-md-2">
              <div class="team-player">
                <b-iconstack font-scale="7">
                  <b-icon stacked icon="circle-fill" style="color:#d91b5c"></b-icon>
                  <b-icon stacked icon="coin" scale="0.5" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="title" style="color: white;">{{$t('pricing.items[5].type')}}</h4>
                <p class="category text-primary">{{$t('pricing.items[5].price')}}</p>
                <p class="description" style="color: white;">
                  {{$t('pricing.items[5].description')}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'pricing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
