<template>
  <div class="carousel-wrapper">
    <div class="carousel-container">
      <transition-group name="slide" tag="div" class="carousel-inner">
        <img
          v-for="(logo, index) in visibleLogos"
          :key="logo + index"
          :src="logo"
          alt="Logo"
          class="carousel-logo"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        "/img/logos/nvidia.png",
        "/img/logos/vivex.png",
        "/img/logos/garena.png",
        "/img/logos/tal.png",
        "/img/logos/zukun.png",
        "/img/logos/acer.png",
        "/img/logos/alpha.png",
        "/img/logos/axis.png",
        "/img/logos/epic.png",
        "/img/logos/fareast.png",
        "/img/logos/gamania.png",
        "/img/logos/hinet.png",
        "/img/logos/kbs.png",
        "/img/logos/lenovo.png",
        "/img/logos/megagrants.png",
        "/img/logos/momo.png",
        "/img/logos/msi.png",
        "/img/logos/netease.png",
        "/img/logos/sanli.png",
        "/img/logos/viverse.png"
      ],
      currentIndex: 0,
    };
  },
  computed: {
    visibleLogos() {
  const logosLength = this.logos.length;
  const endIndex = this.currentIndex + 3;
  if (endIndex <= logosLength) {
    return this.logos.slice(this.currentIndex, endIndex);
  } else {
    return [
      ...this.logos.slice(this.currentIndex, logosLength),
      ...this.logos.slice(0, endIndex - logosLength)
    ];
  }
},
  },
  mounted() {
    this.autoPlay();
  },
  methods: {
    autoPlay() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 3) % this.logos.length;
      }, 2000); // Adjust the speed of autoplay
    },
  },
};
</script>

<style scoped>
.carousel-wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  height: 300px; /* Full viewport height to align relative to the top */
}

.carousel-container {
  /* width: 900px; Adjust width to show three logos */
  height: 300px; /* Adjust height as needed */
  overflow: hidden;
  background-color: transparent; /* Optional background */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: top;
}

.carousel-inner {
  display: flex;
  width: 100%; /* Full width to cover the screen */
  transition: transform 0.3s linear; /* Smooth transition */
}

.carousel-logo {
  width: 200px; /* Set a fixed width for each logo */
  height: 80px; /* Set a fixed height */
  object-fit: contain; /* Maintain the logo's aspect ratio */
  background: transparent; /* Ensure the background is transparent */
  margin: 0 10px; /* Add some margin between logos */
  justify-content: center;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s linear; /* Adjust the timing for smoother effect */
}

.slide-enter {
  transform: translateX(100vw); /* Start from the right */
}

.slide-leave-to {
  transform: translateX(0vw); /* Exit to the left */
}
</style>
